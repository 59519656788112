import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import {
  WeldStatsApiService,
  WelderStatsApiService,
  WeldManagerUserApiService,
  WelderStampIdHistoryApiService,
  WeldLogApiService
} from '../../core/api';
import {
  WeldStats,
  WeldLogParams,
  WelderStats,
  WelderStatsParams,
  WelderStampIdHistory,
  WelderStampIdHistoryParams,
  WeldLog
} from '../../core/models';
import { UserInfoService } from './user-info.service';
import { DropdownService } from './dropdown.service';

@Injectable()
export class ReportingService {
  public refineryId: number;
  public userId: string;
  constructor(
    private weldLogApiServ: WeldLogApiService,
    private welderStatsApiServ: WelderStatsApiService,
    private welderStampIdHistoryApiServ: WelderStampIdHistoryApiService,
    private readonly _srvWeldRole: WeldManagerUserApiService,
    private readonly _srvUserInfo: UserInfoService,
    private readonly _dropdownService: DropdownService
  ) {}

  //#region 'Load'
  public loadRefineries(): Observable<any> {
    return this._srvWeldRole.GetUserRolesByUserAsync(this._srvUserInfo.userId);
  }
  //#endregion

  public setparams(): void {
    const USER = JSON.parse(localStorage.getItem('user'));
    if (USER) {
      this.userId = USER.userId;
      this.refineryId = USER.refineryId;
    }
  }

  //#region 'Get'
  public async GetWeldStats(params: WeldLogParams): Promise<WeldLog[]> {
    this.setparams();
    let tempParams = new WeldLogParams();
    tempParams.weldtodate = `${params.weldtodate}T00:00:00.000Z`;
    tempParams.weldfromdate = `${params.weldfromdate}T00:00:00.000Z`;
    tempParams.divisionid = params.divisionid ? params.divisionid : 0;
    tempParams.groupid = params.groupid ? params.groupid : 0;
    tempParams.unitid = params.unitid ? params.unitid : 0;
    tempParams.refineryid = Number(this.refineryId);

    try {
      const result = await firstValueFrom(this.weldLogApiServ.GetWeldLogsAsync(tempParams));
      return result;
    } catch (error) {
      console.error('Error fetching weld logs:', error);
      throw error;
    }
  }

  public GetWelderStats(params: WelderStatsParams): Promise<WelderStats[]> {
    this.setparams();
    let tempParams = new WelderStatsParams();
    tempParams.todate = `${params.todate}T00:00:00.000Z`;
    tempParams.fromdate = `${params.fromdate}T00:00:00.000Z`;
    tempParams.refineryid = Number(this.refineryId);
    let tempFullName = this._dropdownService.stampAllocation.items.find(
      x => Number(x.id) == Number(params.fullName)
    )?.name;
    tempParams.fullName = tempFullName?.replace(/ *\([^)]*\) */g, ''); //regex removes the stamp number from the fullname

    return new Promise((resolve, reject) => {
      try {
        this.welderStatsApiServ.GetWelderStatsAsync(tempParams).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetWelderStampIdHistory(params: WelderStampIdHistoryParams): Promise<WelderStampIdHistory[]> {
    this.setparams();
    let tempParams = new WelderStampIdHistoryParams();
    tempParams.allocationIdToDate = `${params.allocationIdToDate}T00:00:00.000Z`;
    tempParams.allocationIdFromDate = `${params.allocationIdFromDate}T00:00:00.000Z`;
    tempParams.refineryid = Number(this.refineryId);

    if (params.welderName !== undefined && params.welderName !== null) {
      let tempFullName = this._dropdownService.stampAllocation.items.find(
        x => Number(x.id) == Number(params.welderName)
      )?.name;
      tempParams.welderName = tempFullName?.replace(/ *\([^)]*\) */g, ''); //regex removes the stamp number from the fullname
    }

    if (params.stampNumber !== undefined && params.stampNumber !== null) {
      tempParams.stampNumber = params.stampNumber; //stampNumber filter
    }

    return new Promise((resolve, reject) => {
      try {
        this.welderStampIdHistoryApiServ.GetWelderStampIdHistoryAsync(tempParams).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
  //#endregion
}
