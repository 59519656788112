export class WeldStatsTotal {
  public totalWelds: number;
  public totalAccepted: number;
  public totalInspected: number;
  public totalRejected: number;
  public totalRejectRate: number;
}

export class WeldStatsByJointType {
  public jointTypeId: number;
  public jointTypeName: string;
  public refineryId: number;
  public divisionId: number;
  public weldGroupId: number;
  public unitId: number;
  public statsTotalWelds: number;
  public statsAccepted: number;
  public statsRejected: number;
  public statsRepaired: number;
}
export class WeldStatsByQAType {
  public qaTypeId: number;
  public qaTypeName: string;
  public refineryId: number;
  public divisionId: number;
  public weldGroupId: number;
  public unitId: number;
  public statsTotalWelds: number;
  public statsAccepted: number;
  public statsRejected: number;
  public statsRepaired: number;
}

export class WeldStats {
  public weldStatsTotal: WeldStatsTotal;
  public weldStatsByJointType: WeldStatsByJointType[];
  public weldStatsByQAType: WeldStatsByQAType[];
}
