import { Injectable } from '@angular/core';
import { WeldLogApiService } from '../../core/api/weld-log-api.service';
import { QATypesAndLabel, WeldLogFillerMaterial, WeldLogStampProcess } from '../../core/models';
import { WeldLog, WeldLogParams, WeldNoParams } from '../../core/models/WeldLog.model';
import { WeldLogTable } from '../models/weld-log-table.model';
import { UserInfoService } from './user-info.service';

@Injectable()
export class WeldLogService {
  private logsTable: WeldLogTable[] = [];

  constructor(
    private _srvWeldLog: WeldLogApiService,
    private _srvUserInfo: UserInfoService
  ) {}

  public GetTableWeldLogs(params: WeldLogParams): Promise<WeldLogTable[]> {
    const wlp = new WeldLogParams();
    wlp.weldfromdate = `${params.weldfromdate}T00:00:00.000Z`;
    wlp.weldtodate = `${params.weldtodate}T23:59:59.999Z`;
    wlp.divisionid = params.divisionid ?? 0;
    wlp.groupid = params.groupid ?? 0;
    wlp.unitid = params.unitid ?? 0;
    wlp.refineryid = Number(this._srvUserInfo.refineryId);
    wlp.stampids = params.stampids.slice(0);
    wlp.ewoNumbers = params.ewoNumbers.slice(0);

    return new Promise((resolve, reject) => {
      try {
        this.logsTable = [];
        this._srvWeldLog.GetWeldLogsAsync(wlp).subscribe(res => {
          for (const dt of res) {
            this.logsTable.push(new WeldLogTable(dt));
          }

          resolve(this.logsTable);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetWeldLogs(params: WeldLogParams): Promise<WeldLog[]> {
    const wlp = new WeldLogParams();
    wlp.weldfromdate = `${params.weldfromdate}T00:00:00.000Z`;
    wlp.weldtodate = `${params.weldtodate}T23:59:59.999Z`;
    wlp.divisionid = params.divisionid ?? 0;
    wlp.groupid = params.groupid ?? 0;
    wlp.unitid = params.unitid ?? 0;
    wlp.refineryid = Number(this._srvUserInfo.refineryId);
    wlp.stampids = params.stampids.slice(0);
    wlp.ewoNumbers = params.ewoNumbers.slice(0);

    return new Promise((resolve, reject) => {
      try {
        this._srvWeldLog.GetWeldLogsAsync(wlp).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public AddorUpdateWeldLog(
    existing: boolean,
    draft: boolean,
    log: WeldLog,
    fillerMatDate: string,
    fillermats: number[],
    stampProcesses: any[],
    qaTypeProcesses: any[]
  ): Promise<boolean> {
    this.setupLogVals(log); //setting up empty values for the log
    this.formatAllDates(log); //setting up the dates for the log

    let tempDate = this.getDateFormatted(fillerMatDate);
    log = this.processFillerMaterial(fillermats, log, existing, tempDate);
    log = this.processStamp(stampProcesses, log, existing);
    log = this.processQATypes(qaTypeProcesses, log, existing);
    log.logEntryStatus = !draft;

    log.entryStatus = log.logEntryStatus ? 'Draft' : 'Log';

    return new Promise((resolve, reject) => {
      try {
        if (existing) {
          this._srvWeldLog.UpdateWeldLog(log).subscribe(res => {
            resolve(res);
          });
        } else {
          log.refineryId = Number(this._srvUserInfo.refineryId);
          log.createdBy = this._srvUserInfo.userId;
          log.createdDateTime = new Date().toJSON();
          this._srvWeldLog.AddWeldLog(log).subscribe(res => {
            resolve(res);
          });
        }
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public prepareLogsWithEntries(
    existing: boolean,
    log: WeldLog,
    fillerMatDate: string,
    fillermats: number[],
    stampProcesses: any[],
    qaTypeProcesses: any[]
  ): WeldLog {
    this.setupLogVals(log); //setting up empty values for the log
    this.formatAllDates(log); //setting up the dates for the log
    log.logEntryStatus = true;
    log = this.processStamp(stampProcesses, log, existing);
    if (fillermats != null) {
      let tempDate = this.getDateFormatted(fillerMatDate);
      log = this.processFillerMaterial(fillermats, log, existing, tempDate);
    }
    log = this.processQATypes(qaTypeProcesses, log, existing);
    log.entryStatus = log.logEntryStatus ? 'Draft' : 'Log';
    log.refineryId = Number(this._srvUserInfo.refineryId);
    log.createdBy = this._srvUserInfo.userId;
    log.createdDateTime = new Date().toJSON();

    return log;
  }

  public async AddMultipleWeldLogs(logs: WeldLog[]): Promise<string> {
    const chunkSize = 2000;
    let combinedResponse = '';

    for (let i = 0; i < logs.length; i += chunkSize) {
      const chunk = logs.slice(i, i + chunkSize);
      try {
        const response = await this._srvWeldLog.AddMultipleWeldLogs(chunk).toPromise();
        const refinedResponse = response.substring(1, response.length - 1);
        combinedResponse += refinedResponse + ',';
      } catch (error) {
        throw Error(error);
      }
    }
    combinedResponse = '{' + combinedResponse.substring(0, combinedResponse.length - 1) + '}';
    return combinedResponse;
  }

  public UpdateMultipleWeldLogs(logs: WeldLog[]): Promise<string> {
    logs.forEach(log => {
      log.logEntryStatus = log.original.logEntryStatus;
      log.active = true;
      log.dateWelded = log.dateWelded ? this.getDateFormatted(log.dateWelded.split('T')[0]) : '';
      log.updatedBy = this._srvUserInfo.userId;
      log.updatedDateTime = new Date().toJSON();
    });
    return new Promise((resolve, reject) => {
      try {
        this._srvWeldLog.UpdateMultipleWeldLogs(logs).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public DeleteWeldLog(log: WeldLog, deletionReason: string): Promise<boolean> {
    log.reasonForDeletion = deletionReason;
    log.active = false;
    log.updatedBy = this._srvUserInfo.userId;
    log.updatedDateTime = new Date().toJSON();

    return new Promise((resolve, reject) => {
      this._srvWeldLog.UpdateWeldLog(log).subscribe({
        next: res => {
          resolve(res);
        },
        error: err => {
          reject(Error(err));
        }
      });
    });
  }

  public DeleteMultipleWeldLogs(logs: WeldLog[], deletionReason: string): Promise<string> {
    logs.forEach(log => {
      log.reasonForDeletion = deletionReason;
      log.active = false;
      log.updatedBy = this._srvUserInfo.userId;
      log.updatedDateTime = new Date().toJSON();
    });

    return new Promise((resolve, reject) => {
      this._srvWeldLog.UpdateMultipleWeldLogs(logs).subscribe({
        next: res => {
          resolve(res);
        },
        error: err => {
          reject(Error(err));
        }
      });
    });
  }

  private processFillerMaterial(fillermats: number[], log: WeldLog, existing: boolean, fillerMatDate: string): WeldLog {
    if (!Array.isArray(fillermats)) {
      return log;
    }

    const USER = this._srvUserInfo.userId;
    const DATE = new Date().toJSON();

    for (let i = 0; i < fillermats.length; i++) {
      log.weldLogFillerMaterials[i] = new WeldLogFillerMaterial();
      if (!existing) {
        log.weldLogFillerMaterials[i].weldLogId = 0;
      } else {
        log.weldLogFillerMaterials[i].weldLogId = log.weldLogId;
      }
      log.weldLogFillerMaterials[i].weldLogFillerMaterialId = 0;
      log.weldLogFillerMaterials[i].fillerMaterialId = fillermats[i];
      log.weldLogFillerMaterials[i].fillerMaterialIssuedDate = fillerMatDate;
      log.weldLogFillerMaterials[i].createdBy = USER;
      log.weldLogFillerMaterials[i].createdDateTime = DATE;
      log.weldLogFillerMaterials[i].updatedBy = USER;
      log.weldLogFillerMaterials[i].updatedDateTime = DATE;
      log.weldLogFillerMaterials[i].active = true;
      log.weldLogFillerMaterials[i].reasonForDeletion = null;
    }
    return log;
  }

  public processStamp(stampProcesses: any[], log: WeldLog, existing: boolean): WeldLog {
    if (!Array.isArray(stampProcesses)) {
      return log;
    }

    const USER = this._srvUserInfo.userId;
    const DATE = new Date().toJSON();
    for (let stampProcess of stampProcesses) {
      for (let pid of stampProcess.processids) {
        const DTO = new WeldLogStampProcess();
        DTO.weldLogStampProcessID = 0;
        DTO.weldLogId = existing ? log.weldLogId : 0;
        DTO.stampAllocationId = stampProcess.stampid;
        DTO.stampNumber = '';
        DTO.weldProcessId = pid;
        DTO.rtacceptedRejected = stampProcess.rtacceptedRejected;
        DTO.createdBy = USER;
        DTO.createdDateTime = DATE;
        DTO.updatedBy = USER;
        DTO.updatedDateTime = DATE;
        DTO.active = true;
        DTO.reasonForDeletion = null;
        log.weldLogStampProcesses.push(DTO);
      }
    }
    return log;
  }

  public processQATypes(qaTypeProcesses: any[], log: WeldLog, existing: boolean, test?): WeldLog {
    if (!Array.isArray(qaTypeProcesses)) {
      return log;
    }

    const USER = this._srvUserInfo.userId;
    const DATE = new Date().toJSON();

    qaTypeProcesses.forEach((qaTypeProcess, i) => {
      qaTypeProcess.qaDate.forEach((qaDate, j) => {
        const DTO = new QATypesAndLabel();
        DTO.fieldSequence = j + 1;
        DTO.weldLogQAID = 0;
        DTO.weldLogId = existing ? log.weldLogId : 0;
        DTO.qaTypeID = qaTypeProcess.qaTypeID;
        DTO.qaTypeName = '';
        DTO.description = qaTypeProcess.description;
        DTO.qaTypeLabelID = qaTypeProcess.qaTypeLabelID;
        DTO.qaDate = qaDate['date'];
        DTO.defectTypeID = qaTypeProcess.defectTypeID;
        DTO.createdBy = USER;
        DTO.createdDateTime = DATE;
        DTO.updatedBy = USER;
        DTO.updatedDateTime = DATE;
        DTO.active = true;
        DTO.reasonForDeletion = null;
        DTO.ndeRequestURL = qaTypeProcess.ndeRequestURL;
        log.weldLogQAs.push(DTO);
      });
    });

    return log;
  }

  public editQADate(qaTypeProcesses: any[], log: WeldLog): WeldLog {
    // Shouldn't this iterate over the qaTypeProcesses array and
    // find corresponding entries in the log.weldLogQAs array and update
    // the date there?
    let weldLogQAsToModify = log.weldLogQAs;

    for (let currentQAObject of weldLogQAsToModify) {
      if (
        currentQAObject.qaTypeID == qaTypeProcesses[0].qaTypeID &&
        currentQAObject.qaTypeLabelID == qaTypeProcesses[0].qaTypeLabelID
      ) {
        currentQAObject.qaDate = qaTypeProcesses[0].qaDate[0];
        break;
      }
    }
    return log;
  }

  private getDateFormatted(value: string): string {
    if (value === null || value === undefined || value === '') {
      return '';
    }
    return `${value}T00:00:00.000Z`;
  }

  private formatAllDates(log: WeldLog): void {
    log.rtcompletionDate = this.getDateFormatted(log.rtcompletionDate);
    log.repcompletionDate = this.getDateFormatted(log.repcompletionDate);
    log.pwhtcompletionDate = this.getDateFormatted(log.pwhtcompletionDate);
    log.bhtcompletionDate = this.getDateFormatted(log.bhtcompletionDate);
    log.vtcompletionDate = this.getDateFormatted(log.vtcompletionDate);
    log.pmicompletionDate = this.getDateFormatted(log.pmicompletionDate);
    log.pmivcompletionDate = this.getDateFormatted(log.pmivcompletionDate);
  }

  private setupLogVals(log: WeldLog): void {
    log.active = true;
    log.refineryId = log.refineryId ?? Number(this._srvUserInfo.refineryId);
    log.dateWelded = this.getDateFormatted(log.dateWelded);
    log.weldLogStampProcesses = new Array<WeldLogStampProcess>();
    log.weldLogFillerMaterials = new Array<WeldLogFillerMaterial>();
    log.weldLogQAs = new Array<QATypesAndLabel>();
    log.updatedBy = this._srvUserInfo.userId;
    log.updatedDateTime = new Date().toJSON();
  }

  public GetNextWeldNumberAsync(params: WeldNoParams): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this._srvWeldLog.GetNextWeldNumberAsync(params).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public CloneWeldLogAsync(log: any, cloneAmount: number, keepWeldNoSame?: boolean): Promise<any> {
    log.createdBy = this._srvUserInfo.userId;
    log.createdDateTime = new Date().toJSON();

    return new Promise((resolve, reject) => {
      this._srvWeldLog.CloneWeldLog(log, cloneAmount, keepWeldNoSame).subscribe({
        next: res => {
          resolve(res);
        },
        error: err => {
          reject(Error(err));
        }
      });
    });
  }
}
