import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import {
  WeldStatsTotal,
  WeldStatsByQAType,
  WeldStatsByJointType,
  WeldStats
} from 'src/app/core/models/WeldStats.model';
import {
  TableHeaders,
  WeldLogParams,
  WelderStampIdHistory,
  WelderStampIdHistoryParams,
  WelderStats,
  WelderStatsParams,
  WeldLog,
  QATypesAndLabel,
  WeldLogStampProcess
} from '../../../core/models';
import { DropdownList } from '../../../data/models';
import {
  DropdownService,
  ExportToExcelService,
  LoadingIndicatorService,
  KeypressValidationService,
  ReportingService,
  WeldLogService
} from '../../../data/services';
import { NotificationService, NotificationToast } from '../../shared/notification';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
  providers: [ReportingService, WeldLogService]
})
export class ReportingComponent implements OnInit {
  //#region 'Variables'

  // dropdowns for filters
  public divisions = new DropdownList();
  public units = new DropdownList();
  public groups = new DropdownList();
  public welders = new DropdownList();
  public allwelders = new DropdownList();
  public jointTypes = new DropdownList();
  public qaTypes = new DropdownList();

  public weldLogParams = new WeldLogParams();
  public activeWeldFilterParams = new WeldLogParams(); // holds the active filters; only updates on page initialization and submit filters
  public welderStatsParams = new WelderStatsParams();
  public welderStampIdHistoryParams = new WelderStampIdHistoryParams();
  public activeWelderFilterParams = new WelderStatsParams(); //holds the active filters; only updates on page initialization and submit filters
  public activeWelderStampIdFilterParams = new WelderStampIdHistoryParams(); //holds the active filters; only updates on page initialization and submit filters
  public inactiveFilters = false; //used for custom styling with inactive filters

  // table data
  public logs = new Array<WeldLog>();
  public weldStats: WeldStats = {
    weldStatsTotal: {
      totalWelds: 0,
      totalAccepted: 0,
      totalInspected: 0,
      totalRejected: 0,
      totalRejectRate: 0
    },
    weldStatsByJointType: [],
    weldStatsByQAType: []
  };
  public welderStats = new Array<WelderStats>();
  public welderStampIdHistory = new Array<WelderStampIdHistory>();
  private isLoading = false;

  // excel export
  public exportColumns: any[];
  private excelWeldStatsHeaders = [];
  private excelJointTypeHeaders = [];
  private excelQATypeHeaders = [];
  private excelWelderStatsHeaders = [];
  private excelWelderStampAllocIdHeaders = [];
  public filterFromDate: string;
  public filterToDate: string;

  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private dropdownService: DropdownService,
    private _weldLogService: WeldLogService,
    private _reportServ: ReportingService,
    private _loader: LoadingIndicatorService,
    private _notifSrv: NotificationService,
    public keyValidation: KeypressValidationService,
    private _expExcel: ExportToExcelService,
    public router: Router
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this._loader.show();
    let headers: TableHeaders[] = JSON.parse(localStorage.getItem('tableHeaders'));

    this.initializeDefaultDates();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.initializeDefaultDates();
        console.log('Navigation end detected');
      }
    });

    this.getDropdowns()
      .then(() => this.getStats())
      .then(() => this.loadHeaders(headers))
      .catch(error => console.error('Error during initialization:', error));
  }

  private initializeDefaultDates() {
    console.log('Initializing default dates');
    let tempDate = new Date();

    this.filterToDate = moment(tempDate).format('YYYY-MM-DD');

    if (this.router.url.includes('weld-stats')) {
      this.weldLogParams.weldfromdate = moment(tempDate).subtract(5, 'M').format('YYYY-MM-DD');
      this.filterFromDate = this.weldLogParams.weldfromdate;
      this.weldLogParams.weldtodate = this.filterToDate;
    } else if (this.router.url.includes('welder-stats')) {
      this.welderStatsParams.fromdate = moment(tempDate).subtract(5, 'M').format('YYYY-MM-DD');
      this.filterFromDate = this.welderStatsParams.fromdate;
      this.welderStatsParams.todate = this.filterToDate;
    } else if (this.router.url.includes('welder-stamp-id-history')) {
      this.welderStampIdHistoryParams.allocationIdFromDate = moment(new Date(1990, 0, 1)).format('YYYY-MM-DD');
      this.filterFromDate = this.welderStampIdHistoryParams.allocationIdFromDate;
      this.welderStampIdHistoryParams.allocationIdToDate = this.filterToDate;
    }
  }
  //#endregion 'Angular Life Cycle'

  onDateFromSelect(event: any) {
    const selectedDate = moment(event).format('YYYY-MM-DD');
    this.filterFromDate = selectedDate;

    if (this.router.url.includes('weld-stats')) {
      this.weldLogParams.weldfromdate = selectedDate;
    } else if (this.router.url.includes('welder-stats')) {
      this.welderStatsParams.fromdate = selectedDate;
    } else if (this.router.url.includes('welder-stamp-id-history')) {
      this.welderStampIdHistoryParams.allocationIdFromDate = selectedDate;
    }

    this.checkForInactiveFilters();
  }

  onDateToSelect(event: any) {
    const selectedDate = moment(event).format('YYYY-MM-DD');
    this.filterToDate = selectedDate;

    if (this.router.url.includes('weld-stats')) {
      this.weldLogParams.weldtodate = selectedDate;
    } else if (this.router.url.includes('welder-stats')) {
      this.welderStatsParams.todate = selectedDate;
    } else if (this.router.url.includes('welder-stamp-id-history')) {
      this.welderStampIdHistoryParams.allocationIdToDate = selectedDate;
    }

    this.checkForInactiveFilters();
  }

  //#region 'Load Headers'
  private loadHeaders(headers: TableHeaders[]) {
    this.loadHeaderArray(headers, 'excelWeldStatsHeaders');
    this.loadHeaderArray(headers, 'excelJointTypeHeaders');
    this.loadHeaderArray(headers, 'excelQATypeHeaders');
    this.loadHeaderArray(headers, 'excelWelderStatsHeaders');
    this.loadHeaderArray(headers, 'excelWelderStampAllocIdHeaders');
  }

  private loadHeaderArray(headers: TableHeaders[], arrayName: string) {
    let filteredResponse = headers.filter(x => x.componentName == 'reporting' && x.arrayName == arrayName);

    for (let i = 0; i < filteredResponse.length; i++) {
      this[arrayName].push({
        field: filteredResponse[i].field,
        header: filteredResponse[i].header,
        excelWidth: filteredResponse[i].field.length + 5
      });
    }
  }
  //#endregion

  //#region 'Load'
  private async getStats() {
    this.isLoading = true;
    try {
      switch (this.router.url) {
        case '/reporting/weld-stats':
          console.log(this.weldLogParams);
          // This needs to change so that it gets the data from the weld logs directly, not the stats endpoint.
          this.logs = await this._weldLogService.GetWeldLogs(this.weldLogParams);
          console.log('Returned ' + this.logs.length + ' weld logs');
          this.weldStats = await this.weldStatistics(this.logs);
          console.log(this.weldStats);
          break;
        case '/reporting/welder-stats':
          this.welderStats = await this._reportServ.GetWelderStats(this.welderStatsParams);
          break;
        case '/reporting/welder-stamp-id-history':
          this.welderStampIdHistory = await this._reportServ.GetWelderStampIdHistory(this.welderStampIdHistoryParams);
          break;
      }
    } catch (error) {
      console.error('Error fetching stats:', error);
    } finally {
      this.isLoading = false;
      setTimeout(() => {
        this._loader.hide();
      }, 1000);
    }
  }

  private async getDropdowns() {
    try {
      this.divisions = await this.dropdownService.GetDivisions();
      this.units = await this.dropdownService.GetUnits();
      this.groups = await this.dropdownService.GetWeldGroups();
      this.welders = await this.dropdownService.GetStampAllocations();
      this.allwelders = await this.dropdownService.GetAllStampAllocations();
      this.jointTypes = await this.dropdownService.GetJointTypes();
    } catch (error) {
      console.error('Error fetching dropdowns:', error);
    }
  }

  public async getUnits() {
    this._loader.show();
    if (this.weldLogParams.divisionid === 0) {
      this.units = await this.dropdownService.GetUnits();
      setTimeout(() => {
        this._loader.hide();
      }, 200);
    } else {
      this.weldLogParams.unitid = null;
      this.units = await this.dropdownService.GetUnitsByDivisionAsync(this.weldLogParams.divisionid);
      setTimeout(() => {
        this._loader.hide();
      }, 200);
    }
  }
  //#endregion 'Load'

  //#region 'Notifications'
  private showNotification(msg: NotificationToast) {
    this._notifSrv.sendNotification(msg);
  }
  //#endregion 'Notifications'

  //#region 'General Methods'
  public submitFilter() {
    switch (this.router.url) {
      case '/reporting/weld-stats':
        for (const filter in this.weldLogParams) {
          if (this.weldLogParams[filter] != this.activeWeldFilterParams[filter]) {
            this.inactiveFilters = true;
            break;
          }
        }
        break;
      case '/reporting/welder-stats':
        for (const filter in this.welderStatsParams) {
          if (this.welderStatsParams[filter] != this.activeWelderFilterParams[filter]) {
            this.inactiveFilters = true;
            break;
          }
        }
        break;
      case '/reporting/welder-stamp-id-history':
        for (const filter in this.welderStampIdHistoryParams) {
          if (this.welderStampIdHistoryParams[filter] != this.activeWelderStampIdFilterParams[filter]) {
            this.inactiveFilters = true;
            break;
          }
        }
        break;
    }
    if (Date.parse(this.filterFromDate) > Date.parse(this.filterToDate)) {
      this.showNotification(
        new NotificationToast('error', 'Error: cannot fetch logs, invalid date selection', '', 'error', 6000)
      );
    } else {
      this._loader.show();
      this.getStats();
      this.checkForInactiveFilters();
    }
  }

  //weldStatsParams is tied to the dropdowns.. checking for changes against the stored active params
  public checkForInactiveFilters() {
    this.inactiveFilters = false;

    switch (this.router.url) {
      case '/reporting/weld-stats':
        for (const filter in this.weldLogParams) {
          if (this.weldLogParams[filter] != this.activeWeldFilterParams[filter]) {
            this.inactiveFilters = true;
            break;
          }
        }
        break;
      case '/reporting/welder-stats':
        for (const filter in this.welderStatsParams) {
          if (this.welderStatsParams[filter] != this.activeWelderFilterParams[filter]) {
            this.inactiveFilters = true;
            break;
          }
        }
        break;
      case '/reporting/welder-stamp-id-history':
        this.inactiveFilters = false;
        for (const filter in this.welderStampIdHistoryParams) {
          if (this.welderStampIdHistoryParams[filter] != this.activeWelderStampIdFilterParams[filter]) {
            this.inactiveFilters = true;
            break;
          }
        }
        break;
    }
  }
  //#endregion 'General Methods'

  //#region 'Export to Excel'
  public exportToExcel() {
    switch (this.router.url) {
      case '/reporting/weld-stats':
        this._expExcel.weldStatsTablesToExcel(
          this.excelWeldStatsHeaders,
          this.excelJointTypeHeaders,
          this.excelQATypeHeaders,
          this.weldStats,
          this.activeWeldFilterParams
        );
        break;
      case '/reporting/welder-stats':
        this._expExcel.welderStatsTablesToExcel(
          this.excelWelderStatsHeaders,
          this.welderStats,
          this.activeWelderFilterParams
        );
        break;
      case '/reporting/welder-stamp-id-history':
        this._expExcel.welderStampAllocationIdTablesToExcel(
          this.excelWelderStampAllocIdHeaders,
          this.welderStampIdHistory,
          this.activeWelderStampIdFilterParams
        );
        break;
    }
  }
  //#endregion 'Export to Excel'

  private hasRTorUT(qa: QATypesAndLabel[]): boolean {
    const matchingQAs = qa.filter(q => q.qaTypeName.startsWith('RT') || q.qaTypeName === 'UT - Phased Array');

    return matchingQAs.length > 0;
  }

  private hasRepairWeld(currentWeld: WeldLog, allWelds: WeldLog[]): boolean {
    const relatedWelds = allWelds.filter(w => w.ewonumber === currentWeld.ewonumber && w.weldNo === currentWeld.weldNo);

    return relatedWelds.some(w => w.weldModifier?.startsWith('R') || w.weldRepair === true);
  }

  private isLatestRepair(currentWeld: WeldLog, allWelds: WeldLog[]): boolean {
    const relatedWelds = allWelds.filter(w => w.ewonumber === currentWeld.ewonumber && w.weldNo === currentWeld.weldNo);

    // if this is the original weld, return true (technically it's not the latest repair, but the original
    // weld needs to be counted in the stats)

    // If this is not a repair weld
    if (!currentWeld.weldModifier?.startsWith('R')) {
      return true;
    }

    // This is a repair weld - check if it's the latest repair
    const currentRepairNum = parseInt(currentWeld.weldModifier.substring(1)) || 0;
    return !relatedWelds.some(w => {
      if (!w.weldModifier?.startsWith('R')) return false;
      const otherRepairNum = parseInt(w.weldModifier.substring(1)) || 0;
      return otherRepairNum > currentRepairNum;
    });
  }

  private isAccepted(weldLogStampProcesses: WeldLogStampProcess[]): boolean {
    // Return false if array is null or empty
    if (!weldLogStampProcesses || weldLogStampProcesses.length === 0) return false;

    // Return true only if all entries with non-null rtacceptedRejected are true
    return weldLogStampProcesses
      .filter(process => process.rtacceptedRejected !== null)
      .every(process => process.rtacceptedRejected === true);
  }

  private isRejected(weldLogStampProcesses: WeldLogStampProcess[]): boolean {
    // Return false if array is null or empty
    if (!weldLogStampProcesses || weldLogStampProcesses.length === 0) return false;

    // Return true if any entry has rtacceptedRejected set to false
    return weldLogStampProcesses.some(process => process.rtacceptedRejected === false);
  }

  private tallyOverallStats(
    weld: WeldLog,
    stats: WeldStatsTotal,
    isOriginal: boolean,
    isLatest: boolean,
    isRepaired: boolean
  ): void {
    if (isOriginal) {
      stats.totalWelds++;
      if (this.isAccepted(weld.weldLogStampProcesses)) {
        stats.totalAccepted++;
      }
      if (this.hasRTorUT(weld.weldLogQAs)) {
        stats.totalInspected++;
      }
      // Only count rejects on the original weld. Rejected repairs don't count as rejects.
      if (this.isRejected(weld.weldLogStampProcesses)) {
        stats.totalRejected++;
      }
      if (stats.totalInspected - stats.totalRejected > 0) {
        console.log(stats.totalRejected + ' / ( ' + stats.totalInspected + ' - ' + stats.totalRejected + ' )');
        stats.totalRejectRate = 100 * (stats.totalRejected / (stats.totalInspected - stats.totalRejected));
      }
    }
  }

  private tallyVolumetricStats(
    weld: WeldLog,
    weldStatsByQATypeMap: Map<string, WeldStatsByQAType>,
    isOriginal: boolean,
    isLatest: boolean,
    isRepaired: boolean
  ): void {
    weld.weldLogQAs.forEach(qa => {
      const volumetric = weldStatsByQATypeMap.get(qa.qaTypeName);
      if (volumetric !== undefined && isOriginal) {
        volumetric.statsTotalWelds++;
        if (this.isAccepted(weld.weldLogStampProcesses)) {
          volumetric.statsAccepted++;
        }
        if (this.isRejected(weld.weldLogStampProcesses)) {
          volumetric.statsRejected++;
        }
        if (isRepaired) {
          volumetric.statsRepaired++;
        }
      }
    });
  }

  private tallyJointTypeStats(
    weld: WeldLog,
    weldStatsByJointTypeMap: Map<number, WeldStatsByJointType>,
    isOriginal: boolean,
    isLatest: boolean,
    isRepaired: boolean
  ): void {
    const jointTypeEntry = weldStatsByJointTypeMap.get(weld.jointTypeId)!;
    jointTypeEntry.statsTotalWelds++;

    if (isOriginal) {
      if (isRepaired) {
        jointTypeEntry.statsRepaired++;
      }
      if (this.isAccepted(weld.weldLogStampProcesses)) {
        jointTypeEntry.statsAccepted++;
      } else if (this.isRejected(weld.weldLogStampProcesses)) {
        jointTypeEntry.statsRejected++;
      }
    }
  }

  private async weldStatistics(welds: WeldLog[]): Promise<WeldStats> {
    // Set up overall object for storing statistics
    const weldStatsTotal: WeldStatsTotal = {
      totalWelds: 0,
      totalAccepted: 0,
      totalInspected: 0,
      totalRejected: 0,
      totalRejectRate: 0
    };

    const weldStatsByJointTypeMap = new Map<number, WeldStatsByJointType>();
    // availableJointTypes should be fetched from the joint types service
    // Construct entries for the weld type stats to land in.
    console.log(this.jointTypes);
    this.jointTypes.items.forEach(jointType => {
      weldStatsByJointTypeMap.set(parseInt(jointType.id), {
        jointTypeId: parseInt(jointType.id),
        jointTypeName: jointType.name,
        statsTotalWelds: 0,
        statsAccepted: 0,
        statsRejected: 0,
        statsRepaired: 0,
        // Unused for now
        refineryId: 0,
        divisionId: 0,
        unitId: 0,
        weldGroupId: 0
      });
    });

    // This should be fetched from the QA Types service.
    const weldTypes = ['RT - Weld Quality (Conventional)', 'RT - Weld Quality (Digital)', 'UT - Phased Array'];

    const weldStatsByQATypeMap = new Map<string, WeldStatsByQAType>();
    // Construct elements for the data to land in.
    weldTypes.forEach(name => {
      weldStatsByQATypeMap.set(name, {
        qaTypeName: name,
        statsTotalWelds: 0,
        statsAccepted: 0,
        statsRejected: 0,
        statsRepaired: 0,
        // Unused for now
        qaTypeId: 0,
        refineryId: 0,
        divisionId: 0,
        weldGroupId: 0,
        unitId: 0
      });
    });

    const sortStartTime = performance.now();
    // Filter the welds array
    const filteredWelds = welds.filter(
      weld => weld.weldType === 1 && weld.jointTypeId !== null && weld.logEntryStatus === true
    );

    console.log('Welds array length = ' + filteredWelds.length);

    try {
      // Loop through all the welds and tally up all the stats

      welds.forEach(weld => {
        const isOriginal = !weld.weldModifier || weld.weldModifier === '';
        const isLatest = this.isLatestRepair(weld, welds);
        const isRepaired = this.hasRepairWeld(weld, welds);

        if (isRepaired && !isLatest) {
          return;
        }

        this.tallyOverallStats(weld, weldStatsTotal, isOriginal, isLatest, isRepaired);
        this.tallyVolumetricStats(weld, weldStatsByQATypeMap, isOriginal, isLatest, isRepaired);
        this.tallyJointTypeStats(weld, weldStatsByJointTypeMap, isOriginal, isLatest, isRepaired);
      });
    } catch (error) {
      console.error('Error processing welds:', error);
    }

    // Sort them to be nice.
    const jointTypeSummary = Array.from(weldStatsByJointTypeMap.values()).sort((a, b) =>
      a.jointTypeName.localeCompare(b.jointTypeName)
    );

    console.log(`Gathering stats from ${welds.length} welds took ${(performance.now() - sortStartTime).toFixed(1)}ms`);

    const tempStats: WeldStats = {
      weldStatsTotal: weldStatsTotal,
      weldStatsByJointType: jointTypeSummary,
      weldStatsByQAType: Array.from(weldStatsByQATypeMap.values())
    };

    console.log(tempStats);

    return tempStats;
  }
}
