import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WeldStats, WeldLogParams } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class WeldStatsApiService extends BaseApiService<WeldStats> {
  constructor(protected _http: HttpClient) {
    super(_http, 'weldstats');
  }

  //#region 'Get'
  public GetWeldStatsAsync(stsParams: WeldLogParams): Observable<WeldStats> {
    return this.getById(stsParams, '/GetWeldStatsAsync');
  }
  //#endregion

  //#region 'Create'
  //#endregion

  //#region 'Update'
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
