<div>
  <div class="row">
    <div *ngIf="ndeLinkFromQMDB.length == 0">
      You're about to process <span style="font-weight: bold">{{ selectedLogs.length }} {{ selectedLogs.length <= 1 ? 'log' : 'logs' }}</span>
    </div>
  </div>

  <div *ngIf="ndeFlag == false">
    <div style="font-weight: bold">NDE submitted failed!</div>
  </div>
  <div *ngIf="ndeFlag == true">
    <div style="font-weight: bold">NDE submitted successfully!</div>
  </div>

  <div class="row">
    <div class="col-12 buttons-padding" style="display: table">
      <div *ngIf="formSubmitted == false">
        <p-button (onClick)="cancel()" class="red-btn mt-4 action-btn"><em class="pi pi-times-circle mr-2"></em>Cancel</p-button>
      </div>
      <div *ngIf="formSubmitted == true">
        <p-button (onClick)="cancel()" class="mt-4 mr-2 action-btn"><em class="pi pi-times-circle mr-2"></em>Close</p-button>
      </div>
      <p-button class="mt-4 mr-2 action-btn" (onClick)="submitNewLog()" [disabled]="formSubmitted">Submit NDEs</p-button>
    </div>
  </div>
</div>
