import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QMDBService } from 'src/app/core/api/nde-api.service';
import { HttpClient } from '@angular/common/http';
import { WeldLog } from '../../../../core/models';
import {
  DropdownService,
  KeypressValidationService,
  LoadingIndicatorService,
  WeldManagerUserService,
  WeldCertificationService
} from '../../../../data/services';
import { NotificationToast, NotificationService } from '../../../shared/notification';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-create-nde',
  templateUrl: './create-nde.component.html',
  styleUrls: ['./create-nde.component.scss']
})
export class CreateNdeComponent implements OnInit {
  //#region 'Variables'
  @Input() public userPermissions: any;
  @Input() public hideActionBtns: boolean;
  @Input() public selectedLogs: WeldLog[];
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
  public qmdbRequest: any;
  public ndeLinkFromQMDB: string = '';
  public ndeFlag: boolean;
  public matchedweldStage = 'None';
  public matchedweldTest = '';
  formSubmitted = false;

  //#region 'Angular Life Cycle'
  constructor(
    public _srvDropDown: DropdownService,
    private _srvNotify: NotificationService,
    private _loader: LoadingIndicatorService,
    public keyValidation: KeypressValidationService,
    public _srvWCS: WeldCertificationService,
    public _srvWeldUsers: WeldManagerUserService,
    protected _http: HttpClient,
    private data: QMDBService,
    private _notify: MessageService
  ) {}
  //#endregion

  // #region 'General Methods'
  ngOnInit(): void {
    this.clearAllFields();
    this.getNameAndEmail(this.selectedLogs);
  }

  ngOnDestroy(): void {
    this.clearAllFields();
  }

  private getNameAndEmail(selectedLogs) {
    this._srvWCS.loadRefineries().subscribe(dto => {
      let name = dto[0].lastName + ', ' + dto[0].firstName;
      let email = dto[0].emailId;
      let site = dto[0].refineryName.replace(' Refinery', '');
      this.constructBrandNewJSON(selectedLogs, name, email, site);
    });
  }

  public constructBrandNewJSON(selectedLogs: WeldLog[], name: string, email: string, site: string) {
    // 1) Create a mapping NDE <--> weld logs
    let ndeWeldLogMap = this.getNDEWeldLogMapping(selectedLogs);

    //2) Add EWO to each entry in ndeWeldLogMap
    let ndeWeldLogEwoMap = this.attachEWONumberToNDEWeldLogMap(ndeWeldLogMap);

    //3) Add drawing number to each entry in ndeWeldLogEwoMap
    let ndeWeldLogEwoDrawMap = this.attachDrawingNumberToNDEWeldLogEwoMap(ndeWeldLogEwoMap);

    //4) Group them
    let NDEAndEWOPairs = this.getNDEAndEWOPairs(ndeWeldLogEwoDrawMap);

    //5) Group NDE and EWO pairs with logs
    let groupedNDEsEWOAndLogs = this.groupNDEAndEWOPairsWithLogs(NDEAndEWOPairs, ndeWeldLogEwoDrawMap);

    //6) Group logs by drawing
    let groupedLogsByDrawing = this.groupLogsByDrawing(groupedNDEsEWOAndLogs);

    //7) Replace each log for its relevant info
    let qmdbBodyStr = this.extractAndPutRelevantInfo(groupedLogsByDrawing);

    // Truncate the UT - Thickness, TIE Point type since QMDB only has the UT - Thickness type
    let qmdbBody = JSON.parse(JSON.stringify(qmdbBodyStr).replace('UT - Thickness, TIE Point', 'UT - Thickness'));

    if (qmdbBody.length !== 0) {
      let finalRequest = {
        requesterEmail: name,
        requesterName: email,
        site: site,
        ndeRequest: qmdbBody
      };
      this.qmdbRequest = finalRequest;
    } else {
      this.qmdbRequest = [];
    }
  }

  private getNDEWeldLogMapping(selectedLogs: WeldLog[]) {
    let ndeWeldLogMap = [];
    for (let i = 0; i < selectedLogs.length; i++) {
      let currentLog = selectedLogs[i];
      let qaListOfCurrentLog = currentLog.weldLogQAs;

      for (let j = 0; j < qaListOfCurrentLog.length; j++) {
        let ndeURLTmp = qaListOfCurrentLog[j].ndeRequestURL;
        if (ndeURLTmp === null) {
          let currentQA = qaListOfCurrentLog[j].qaTypeName;
          let currentQADesc = qaListOfCurrentLog[j].description;
          let entryInMap = [currentQA, currentLog, currentQADesc];
          ndeWeldLogMap.push(entryInMap);
        }
      }
    }

    return ndeWeldLogMap;
  }

  private attachEWONumberToNDEWeldLogMap(ndeWeldLogMap: any[]) {
    // ndeWeldLogMap is an array of arrays
    // the second index in each array is the weld log

    let ndeWeldLogEwoMap = [];
    for (let i = 0; i < ndeWeldLogMap.length; i++) {
      let currentNDEType = ndeWeldLogMap[i][0];
      let currentLog = ndeWeldLogMap[i][1];
      let currentNDEDesc = ndeWeldLogMap[i][2];
      let ewoNumber = currentLog.ewonumber;
      let entryInMap = [currentNDEType, currentLog, ewoNumber, currentNDEDesc];
      ndeWeldLogEwoMap.push(entryInMap);
    }

    return ndeWeldLogEwoMap;
  }

  private attachDrawingNumberToNDEWeldLogEwoMap(ndeWeldLogEwoMap: any[]) {
    // ndeWeldLogEwoMap is an array of arrays
    // first index in each array is the NDE type, second index is the weld log
    // third index is the ewo number

    let ndeWeldLogEwoDrawMap = [];
    for (let i = 0; i < ndeWeldLogEwoMap.length; i++) {
      let currentNDEType = ndeWeldLogEwoMap[i][0];
      let currentLog = ndeWeldLogEwoMap[i][1];
      let ewoNumber = ndeWeldLogEwoMap[i][2];
      let currentNDEDesc = ndeWeldLogEwoMap[i][3];
      let drawingNumber = currentLog.drawing;
      let groupName = currentLog.weldGroupName;
      let entryInMap = [currentNDEType, currentLog, ewoNumber, drawingNumber, groupName, currentNDEDesc];
      ndeWeldLogEwoDrawMap.push(entryInMap);
    }

    return ndeWeldLogEwoDrawMap;
  }

  private getNDEAndEWOPairs(ndeWeldLogEwoDrawMap: any[]) {
    //0) Exclude NDEType list
    const exlNDEType = [
      'Copper Sulfate',
      'Hydrotest',
      'Positive Material Ident. Verification',
      'Post Weld Heat Treatment',
      'VT - Visual Test'
    ];
    //1) Sort the ndeWeldLogEwoDrawMap alphabetically by NDE type
    ndeWeldLogEwoDrawMap.sort((a, b) => a[0].localeCompare(b[0]));

    //2) Create a combination of all the NDE and EWO
    let NDEAndEWOCombination = [];
    for (let i = 0; i < ndeWeldLogEwoDrawMap.length; i++) {
      if (!exlNDEType.includes(ndeWeldLogEwoDrawMap[i][0])) {
        let currentNDEType = ndeWeldLogEwoDrawMap[i][0];
        let ewoNumber = ndeWeldLogEwoDrawMap[i][2];
        let groupName = ndeWeldLogEwoDrawMap[i][4];
        let currentNDEDesc = ndeWeldLogEwoDrawMap[i][5];
        let entryInCombination = [currentNDEType, ewoNumber, groupName, currentNDEDesc];

        // if the combination is not already in the array, add it
        if (!NDEAndEWOCombination.some(arr => arr[0] === currentNDEType && arr[1] === ewoNumber)) {
          NDEAndEWOCombination.push(entryInCombination);
        }
      }
    }

    return NDEAndEWOCombination;
  }

  private groupNDEAndEWOPairsWithLogs(NDEAndEWOPairs: any[], ndeWeldLogEwoDrawMap: any[]) {
    let logsGroupedByNDEAndEWO = [];
    for (let i = 0; i < NDEAndEWOPairs.length; i++) {
      let currentNDEType = NDEAndEWOPairs[i][0];
      let currentEWO = NDEAndEWOPairs[i][1];
      let currentGroupName = NDEAndEWOPairs[i][2];
      let currentNDEDesc = NDEAndEWOPairs[i][3];

      let logsWithSameNDEAndEWO = ndeWeldLogEwoDrawMap.filter(
        entry => entry[0] == currentNDEType && entry[2] == currentEWO
      );

      let groupedNDEsEWOLogs = {
        ndeType: currentNDEType,
        ndeAbbreviation: currentNDEDesc,
        ewoNumber: currentEWO,
        groupName: currentGroupName,
        drawings: logsWithSameNDEAndEWO
      };

      logsGroupedByNDEAndEWO.push(groupedNDEsEWOLogs);
    }

    return logsGroupedByNDEAndEWO;
  }

  private groupLogsByDrawing(inputArray: any[]): any[] {
    return inputArray.map(item => {
      const groupedLogs: { [key: string]: any[] } = {};
      if (Array.isArray(item.drawings)) {
        item.drawings.forEach((log: any[]) => {
          const drawing = log[3];
          const weldLog = log[1];
          if (groupedLogs[drawing]) {
            groupedLogs[drawing].push(weldLog);
          } else {
            groupedLogs[drawing] = [weldLog];
          }
        });
      }
      return { ...item, drawings: groupedLogs };
    });
  }

  private extractAndPutRelevantInfo(log: any[]) {
    let updatedLog = JSON.parse(JSON.stringify(log)); // Deep copy of the original log array

    for (let i = 0; i < updatedLog.length; i++) {
      let oneLog = updatedLog[i];
      let logArrayKey = oneLog.drawings;
      let relevantNDEType = oneLog.ndeType;
      const updatedDrawings: any[] = [];

      // Loop over the key-value pairs
      for (const [key, value] of Object.entries(logArrayKey)) {
        const updatedValue = this.extractInfoFromWldLogs(value, relevantNDEType);

        // Create drawing objects and add them to the updatedDrawings array
        for (const item of updatedValue) {
          updatedDrawings.push({
            drawing: key !== 'null' ? key : '',
            ...item
          });
        }
      }

      oneLog.drawings = updatedDrawings;
    }

    return updatedLog;
  }

  private extractInfoFromWldLogs(nestedLog: any, relevantNDEType: string) {
    let bodyArr = [];

    for (let i = 0; i < nestedLog.length; i++) {
      let eachLog = nestedLog[i];
      let obj = {};
      let matchingQATypes = eachLog.weldLogQAs.filter(entry => entry.qaTypeName === relevantNDEType);
      let matchingQATypeName = matchingQATypes[0].qaTypeName;
      let matchingQATypesLabelNames = matchingQATypes[0].labelNames;
      let QATypesLabelNamesLen = matchingQATypesLabelNames.split(',').length;
      let multipleQATypes = matchingQATypesLabelNames.includes(',');

      obj['weldNo'] = eachLog.weldNo != null ? eachLog.weldNo : '';
      obj['weldStage'] = this.mappedQATypeWeldStage(
        matchingQATypeName,
        multipleQATypes ? matchingQATypesLabelNames.split(',')[i].trim() : matchingQATypesLabelNames
      );
      obj['testStage'] = this.mappedQATypeWeldTest(
        matchingQATypeName,
        multipleQATypes ? matchingQATypesLabelNames.split(',')[i].trim() : matchingQATypesLabelNames,
        QATypesLabelNamesLen
      );
      obj['stencil'] = [...new Set(eachLog.weldLogStampProcesses.map(process => process.stampNumber))].join(', ');
      obj['size'] = eachLog.sizeName != null ? eachLog.sizeName : '';
      obj['process'] = eachLog.weldLogStampProcesses[0].weldProcessName;
      obj['thickness'] = eachLog.wallThickness != null ? eachLog.wallThickness : '';
      obj['schedule'] =
        eachLog.scheduleName != null ? eachLog.scheduleName.toLowerCase().replace('sch', '').trim() : '';

      bodyArr.push(obj);
    }

    return bodyArr;
  }

  public mappedQATypeWeldStage(qaType: string, qaTypeLabel: string) {
    const qaTypeNameLabel = qaType + ':' + qaTypeLabel;

    switch (qaTypeNameLabel) {
      case 'Brinell Hardness Testing:Completion':
        return (this.matchedweldStage = 'After PWHT (A)');
      case 'PT - Dye Penetrant:Final pass':
        return (this.matchedweldStage = 'Before PWHT (B)');
      case 'PT - Dye Penetrant:After stress':
        return (this.matchedweldStage = 'After PWHT (A)');
      case 'RT - Weld Quality (Conventional):Before':
        return (this.matchedweldStage = 'Before PWHT (B)');
      case 'RT - Weld Quality (Conventional):After':
        return (this.matchedweldStage = 'After PWHT (A)');
      case 'RT - Weld Quality (Digital):Before':
        return (this.matchedweldStage = 'Before PWHT (B)');
      case 'RT - Weld Quality (Digital):After':
        return (this.matchedweldStage = 'After PWHT (A)');
      case 'UT - Phased Array:Before':
        return (this.matchedweldStage = 'Before PWHT (B)');
      case 'UT - Phased Array:After stress':
        return (this.matchedweldStage = 'After PWHT (A)');
      default:
        return 'None';
    }
  }

  public mappedQATypeWeldTest(qaType: string, qaTypeLabel: string, qaTypeLabelLen: string) {
    let qaTypeNameLabel = qaType + ':' + qaTypeLabel;
    if (qaType === 'MT - Dry Particle' || qaType === 'MT - Wet Fluorescent')
      qaTypeNameLabel = qaType + ':' + qaTypeLabel + ':' + qaTypeLabelLen;

    switch (qaTypeNameLabel) {
      case 'UT - Thickness:Before':
        return (this.matchedweldTest = 'Existing');

      case 'UT - Phased Array:Before':
        return (this.matchedweldTest = 'First Pass');
      case 'MT - Dry Particle:First pass:3':
        return (this.matchedweldTest = 'First Pass');
      case 'MT - Wet Fluorescent:First pass:3':
        return (this.matchedweldTest = 'First Pass');
      case 'PT - Dye Penetrant:First pass':
        return (this.matchedweldTest = 'First Pass');
      case 'PT - Dye Penetrant:Final pass':
        return (this.matchedweldTest = 'First Pass');
      case 'MT - Dry Particle:Completion:1':
        return (this.matchedweldTest = 'Final');

      case 'MT - Dry Particle:First pass:2':
        return (this.matchedweldTest = 'Prep');
      case 'MT - Wet Fluorescent:First pass:2':
        return (this.matchedweldTest = 'Prep');
      case 'PT - Dye Penetrant:Prep':
        return (this.matchedweldTest = 'Prep');
      case 'MT - Dry Particle:Prep:1':
        return (this.matchedweldTest = 'Prep');
      case 'MT - Wet Fluorescent:Prep:1':
        return (this.matchedweldTest = 'Prep');

      case 'MT - Dry Particle:After stress:3':
        return (this.matchedweldTest = 'After');
      case 'MT - Wet Fluorescent:After stress:3':
        return (this.matchedweldTest = 'After');

      case 'UT - Thickness, TIE Point:Date':
        return (this.matchedweldTest = 'No Stage');

      default:
        return 'Final Pass';
    }
  }

  public clearAllFields() {
    this.qmdbRequest = '';
    this.ndeLinkFromQMDB = '';
  }

  public submitNewLog() {
    console.log(this.qmdbRequest);
    if (this.qmdbRequest.length !== 0) {
      this.data.sendNDEs(this.qmdbRequest).subscribe({
        next: data => {
          if (data.includes('True')) {
            this.showNotification(
              new NotificationToast(
                'success',
                'NDE URL updated',
                'QA completion update successfully processed',
                'success',
                6000
              )
            );
            this.ndeFlag = true;
            this.formSubmitted = true;
          } else if (data.error === null && data.totalItems > 0) {
            this.showNotification(
              new NotificationToast(
                'success',
                'NDE successfully sent',
                data.totalItems + ' log processed',
                'success',
                6000
              )
            );
            this.ndeFlag = true;
            this.formSubmitted = true;
          } else {
            this.showNotification(new NotificationToast('error', 'NDE unsuccessfully sent', data.error, 'error', 6000));
            this.ndeFlag = false;
            this.formSubmitted = false;
          }
        },
        error: error => {
          this.ndeFlag = false;
          this.formSubmitted = false;
          this.handleNotFoundError(error);
        }
      });
    } else {
      this._notify.add({
        key: 'error',
        sticky: true,
        severity: 'warn',
        summary: `No new applicable QAType to be sent to QMDB for this Weld log'.`,
        detail: ''
      });
    }
  }

  private handleNotFoundError(errorMsg: string) {
    this.showNotification(new NotificationToast('error', 'NDE unsuccessfully sent', errorMsg, 'error', 6000));
    // Additional logic to handle 404 error
  }
  // #endregion 'General Methods'

  //#region 'Notification'
  private showNotification(msg: NotificationToast) {
    this._srvNotify.sendNotification(msg);
  }
  //#endregion 'Notification'

  //#region 'Events'
  //emit to parent component to close form
  public cancel(): void {
    this.clearAllFields();
    this.onCancel.emit();
    if (this.formSubmitted) {
      window.location.reload();
    }
  }
  //#endregion
}
