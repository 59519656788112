import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import {
  Division,
  FillerMaterial,
  Material,
  Role,
  Refinery,
  RTPercentage,
  Size,
  Unit,
  UserRole,
  Welder,
  WeldGroup,
  WeldProcess,
  WPS,
  ContractCompany,
  ContractorIdentification
} from 'src/app/core/models';
import {
  ContractCompanyService,
  ContractorIdentificationService,
  DivisionService,
  DropdownService,
  FillerMaterialService,
  LoadingIndicatorService,
  MaterialService,
  RefineryService,
  RoleService,
  SizeService,
  UnitService,
  UserInfoService,
  WelderService,
  WeldGroupService,
  WeldManagerUserService,
  WeldProcessService,
  WPSService
} from 'src/app/data/services';
import { NotificationService, NotificationToast } from '../../shared/notification';
import { RTPercentageService } from 'src/app/data/services/rtpercentage.service';
import { ContractWelderPhotoComponent } from './add-edit-admin/contract-welder-photo/contract-welder-photo.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  //#region 'Variables'
  @ViewChild(ContractWelderPhotoComponent) photoComponent: ContractWelderPhotoComponent;

  public activeData: boolean = false; //true - pull only active users/welders : false - pull both active and inactive users/welders
  private successNotify: boolean = false; //used to display notification to user after successful Add/Update api call

  // User refinery
  private refineryId: number;
  private userId: string;

  // Dynamic Table Variables
  public dataType: any; //object used to get dynamic naming based on the dataset used
  public tableData = [];
  public tableCols = [];

  //Context Menu
  public contextMenuItems: MenuItem[];
  public selectedRow: { [key: string]: any } = {};

  // add/edit form
  public displayForm = false;
  public existing: boolean; //New or exisitng
  public formHeader: string;

  // Delete dialog
  public displayDeleteDialog = false;
  public reasonForDeletion = '';

  // Activate Welder dialog
  public displayActivateWelderDialog = false;
  public activateChevronStampNumber = '';
  public activateContractorStampNumber = '';
  public activateContractCompanyId: number;
  public isContractWelder: boolean;
  public isValidFlags = {
    chevronStampNumber: true,
    contractorStampNumber: true,
    contractCompanyId: true
  };

  //Welder
  public welderTypeFilter: string = null;
  public allWelderDataStored = [];

  //Contract Welder Photo
  public displayPhotoDialog = false;
  public companyFilter: string = null;
  public allPhotoDataStored = [];

  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    public datePipe: DatePipe,
    private _loader: LoadingIndicatorService,
    private _notifSrv: NotificationService,
    private _notify: MessageService,
    public _srvDropDown: DropdownService,
    private _srvRefinery: RefineryService,
    private _srvDivision: DivisionService,
    private _srvFillMat: FillerMaterialService,
    private _srvMaterial: MaterialService,
    private _srvSize: SizeService,
    private _srvUnit: UnitService,
    private _srvUserInfo: UserInfoService,
    private _srvGroup: WeldGroupService,
    private _srvUsers: WeldManagerUserService,
    private _srvWPS: WPSService,
    private _srvWelders: WelderService,
    public router: Router,
    public _srvRTPercentage: RTPercentageService,
    public _srvWeldProcess: WeldProcessService,
    public _srvRole: RoleService,
    public _srvContractCompany: ContractCompanyService,
    public _srvContractorIdentification: ContractorIdentificationService
  ) {}

  ngOnInit(): void {
    this._loader.show();
    this.refineryId = Number(this._srvUserInfo.refineryId);
    this.userId = this._srvUserInfo.userId;
    this.dataType = this.setupDataTypes();
    this.setupTableCols();
    this.getData();
  }
  //#endregion 'Angular Life Cycle'

  public setparams(): void {
    const USER = JSON.parse(localStorage.getItem('user'));
    if (USER) {
      this.userId = USER.userId;
      this.refineryId = USER.refineryId;
    }
  }

  //#region 'Shared Methods'

  public isDate(value: any): boolean {
    return !isNaN(Date.parse(value));
  }

  /*returned array is used for dynamic names: title - Page Titles, label -Button Labels, field - model property name,
    globalField - global dataset vs refinery specific, formControlName - Form Control id/name, & formControlLabel - Form Control Labels */
  private setupDataTypes() {
    switch (this.router.url) {
      case '/admin/user':
        this.tableData = [] as UserRole[];
        return {
          title: 'Weld Manager Users',
          label: 'User',
          field: 'userId',
          globalField: false,
          formControlName: 'roleId',
          formControlLabel: 'Role'
        };

      case '/admin/welder':
        this.tableData = [] as Welder[];
        return {
          title: 'Welders',
          label: 'Welder',
          field: 'welderId',
          globalField: false,
          formControlName: 'stampNumber',
          formControlLabel: 'Chevron Stamp'
        };

      case '/admin/filler-materials':
        this.tableData = [] as FillerMaterial[];
        return {
          title: 'Filler Materials',
          label: 'Filler Material',
          field: 'fillerMaterialName',
          globalField: true,
          formControlName: 'fillerMaterialName',
          formControlLabel: 'Filler Material Name'
        };

      case '/admin/materials':
        this.tableData = [] as Material[];
        return {
          title: 'Materials',
          label: 'Material',
          field: 'materialName',
          globalField: true,
          formControlName: 'materialName',
          formControlLabel: 'Material Name'
        };

      case '/admin/pipe-sizes':
        this.tableData = [] as Size[];
        return {
          title: 'Pipe Sizes',
          label: 'Pipe Size',
          field: 'sizeName',
          globalField: true,
          formControlName: 'sizeName',
          formControlLabel: 'Pipe Size Name'
        };

      case '/admin/wps':
        this.tableData = [] as WPS[];
        return {
          title: 'WPS',
          label: 'WPS',
          field: 'wpsname',
          globalField: false,
          formControlName: 'wpsname',
          formControlLabel: 'WPS Name'
        };

      case '/admin/switchsite':
        this.tableData = [] as UserRole[];
        return {
          title: 'Switch User Role',
          label: 'User Role',
          field: 'refineryId',
          globalField: false,
          formControlName: 'refineryId',
          formControlLabel: 'Current Refinery'
        };

      case '/admin/email':
        this.tableData = [] as Refinery[];
        return {
          title: 'Email Settings',
          globalField: false,
          label: 'Email Settings'
        };

      case '/admin/division':
        this.tableData = [] as Division[];
        return {
          title: 'Divisions',
          label: 'Division',
          field: 'divisionCode',
          globalField: false,
          field2: 'divisionDescription',
          formControlName: 'divisionCode',
          formControlLabel: 'Division Code',
          formControlName2: 'divisionDescription',
          formControlLabel2: 'Division Description'
        };

      case '/admin/unit':
        this.tableData = [] as Unit[];
        return {
          title: 'Units',
          label: 'Unit',
          field: 'unitCode',
          globalField: false,
          field2: 'unitDescription',
          formControlName: 'unitCode',
          formControlLabel: 'Unit Code',
          formControlName2: 'unitDescription',
          formControlLabel2: 'Unit Description'
        };

      case '/admin/group':
        this.tableData = [] as WeldGroup[];
        return {
          title: 'Groups',
          label: 'Group',
          field: 'weldGroupName',
          globalField: false,
          formControlName: 'weldGroupName',
          formControlLabel: 'Group Name'
        };

      case '/admin/rtpercentage':
        this.tableData = [] as RTPercentage[];
        return {
          title: 'RT Percentage',
          label: 'RT Percentage',
          field: 'rtPercentage',
          globalField: false,
          formControlName: 'rtPercentage',
          formControlLabel: 'RT Percentage'
        };

      case '/admin/weldprocess':
        this.tableData = [] as WeldProcess[];
        return {
          title: 'Weld Process',
          label: 'Weld Process',
          field: 'weldProcessName',
          globalField: true,
          formControlName: 'weldProcessName',
          formControlLabel: 'Weld Process'
        };

      case '/admin/rolepermission':
        this.tableData = [] as Role[];
        return {
          title: 'Role Name',
          label: 'Role Name',
          field: 'roleName',
          globalField: false,
          formControlName: 'roleName',
          formControlLabel: 'Role Name'
        };

      case '/admin/contract-company':
        this.tableData = [] as ContractCompany[];
        return {
          title: 'Contract Company',
          label: 'Contract Company',
          field: 'contractCompanyName',
          globalField: false,
          formControlName: 'contractCompanyName',
          formControlLabel: 'Company Name'
        };

      case '/admin/contract-welder':
        this.tableData = [] as ContractorIdentification[];
        return {
          title: 'Contract Welder',
          label: 'Contract Welder',
          field: 'contractorIdentificationId',
          globalField: false,
          formControlName: 'photo',
          formControlLabel: 'Contract Welder Photo'
        };

      default:
        return '';
    }
  }

  /*Dynamic Table Columns are setup here based on the router url */
  private setupTableCols() {
    switch (this.router.url) {
      case '/admin/user':
        this.tableCols = [
          { field: 'userName', header: 'User' },
          { field: 'emailId', header: 'Email' },
          { field: 'roleName', header: 'Role' },
          { field: 'status', header: 'Status' }
        ];
        break;

      case '/admin/welder':
        this.tableCols = [
          { field: 'welderName', header: 'Welder' },
          { field: 'emailId', header: 'Email' },
          { field: 'stampNumber', header: 'Stamp' },
          { field: 'status', header: 'Status' },
          { field: 'stampDate', header: 'Stamp Dates' },
          { field: 'welderType', header: 'Welder Type' }
        ];
        break;

      case '/admin/contract-company':
        this.tableCols = [
          { field: 'contractCompanyName', header: 'Company Name' },
          { field: 'status', header: 'Status' },
          { field: 'contractDates', header: 'Contract Dates' },
          { field: 'createdBy', header: 'Created By' },
          { field: 'updatedDateTime', header: 'Updated Date' },
          { field: 'updatedBy', header: 'Updated By' }
        ];
        break;

      case '/admin/contract-welder':
        this.tableCols = [
          { field: 'fullName', header: 'Welder' },
          { field: 'contractCompanyName', header: 'Company Name' },
          { field: 'photo', header: 'Photo' },
          { field: 'status', header: 'Status' },
          { field: 'createdDateTime', header: 'Created Date' },
          { field: 'createdBy', header: 'Created By' },
          { field: 'updatedDateTime', header: 'Updated Date' },
          { field: 'updatedBy', header: 'Updated By' }
        ];
        break;

      case '/admin/email':
        this.tableCols = [
          { field: 'certValidityDays', header: 'Certification Validity Time' },
          { field: 'reminderEmail1', header: 'Reminder Email 1' },
          { field: 'reminderEmail2', header: 'Reminder Email 2' },
          { field: 'reminderEmail3', header: 'Reminder Email 3' },
          { field: 'reminderEmailCopyTo', header: 'Email Copy To' },
          { field: 'updatedDateTime', header: 'Updateded Date' },
          { field: 'updatedBy', header: 'Updateded By' }
        ];
        break;

      case '/admin/division':
        this.tableCols = [
          { field: 'divisionCode', header: 'Division Code' },
          { field: 'divisionDescription', header: 'Division Description' },
          { field: 'status', header: 'Status' },
          { field: 'createdDateTime', header: 'Created Date' },
          { field: 'createdBy', header: 'Created By' },
          { field: 'updatedDateTime', header: 'Updateded Date' },
          { field: 'updatedBy', header: 'Updateded By' }
        ];
        break;

      case '/admin/unit':
        this.tableCols = [
          { field: 'unitCode', header: 'Unit Code' },
          { field: 'unitDescription', header: 'Unit Description' },
          { field: 'divisionDescription', header: 'Division' },
          { field: 'status', header: 'Status' },
          { field: 'createdDateTime', header: 'Created Date' },
          { field: 'createdBy', header: 'Created By' },
          { field: 'updatedDateTime', header: 'Updateded Date' },
          { field: 'updatedBy', header: 'Updateded By' }
        ];
        break;

      case '/admin/switchsite':
        this.tableCols = [
          { field: 'refineryName', header: 'Refinery' },
          { field: 'roleName', header: 'Role' }
        ];
        break;

      default:
        this.tableCols = [
          { field: `${this.dataType.field}`, header: `${this.dataType.label}` },
          { field: 'status', header: 'Status' },
          { field: 'createdDateTime', header: 'Created Date' },
          { field: 'createdBy', header: 'Created By' },
          { field: 'updatedDateTime', header: 'Updated Date' },
          { field: 'updatedBy', header: 'Updated By' }
        ];
    }
  }

  /*API calls to get datasets are made here based on the router url */
  public async getData() {
    this.setparams();
    switch (this.router.url) {
      case '/admin/user':
        this.tableData = await this._srvUsers.GetUserRolesByRefineryAsync(this.refineryId, this.activeData);
        this._srvDropDown.GetRoles();
        break;

      case '/admin/welder':
        this.tableData = await this._srvWelders.GetWelderStampsByRefineryAsync(this.refineryId, this.activeData);
        this.allWelderDataStored = [...this.tableData];
        this._srvDropDown.GetContractCompanies();
        this._srvDropDown.loadWelderType();
        break;

      case '/admin/contract-company':
        this.tableData = await this._srvContractCompany.GetContractCompanyByRefineryAsync(
          this.refineryId,
          this.activeData
        );
        break;

      case '/admin/contract-welder':
        this.tableData = await this._srvContractorIdentification.GetContractorIdentificationByRefineryAsync(
          this.refineryId,
          this.activeData
        );
        this.allPhotoDataStored = [...this.tableData];
        this._srvDropDown.GetContractCompanies();
        break;

      case '/admin/filler-materials':
        this.tableData = await this._srvFillMat.GetFillerMaterialsAsync(this.activeData);
        break;

      case '/admin/materials':
        this.tableData = await this._srvMaterial.GetMaterialAsync(this.activeData);
        break;

      case '/admin/pipe-sizes':
        this.tableData = await this._srvSize.GetSizeAsync(this.activeData);
        break;

      case '/admin/wps':
        this.tableData = await this._srvWPS.GetWPsByRefineryAsync(this.refineryId, this.activeData);
        break;

      case '/admin/switchsite':
        this.tableData = await this._srvUsers.GetAvailableUserRolesByUserAsync();
        this._srvDropDown.GetUserRoles();
        break;

      case '/admin/division':
        this.tableData = await this._srvDivision.GetDivisionsByRefineryAsync(this.refineryId, this.activeData);
        break;

      case '/admin/unit':
        this.tableData = await this._srvUnit.GetUnitsByRefineryAsync(this.refineryId, this.activeData);
        this._srvDropDown.GetDivisions();
        break;

      case '/admin/group':
        this.tableData = await this._srvGroup.GetWeldGroupsByRefineryAsync(this.refineryId, this.activeData);
        break;

      case '/admin/email':
        let data = await this._srvRefinery.GetRefineriesAsyncById(this.refineryId);
        this.tableData.push(data[0]);
        break;

      case '/admin/rtpercentage':
        this.tableData = await this._srvRTPercentage.GetRTPercentageByRefineryAsync(this.refineryId, this.activeData);
        break;

      case '/admin/weldprocess':
        this.tableData = await this._srvWeldProcess.GetWeldProcessesAsync(this.activeData);
        break;

      case '/admin/rolepermission':
        this.tableData = await this._srvRole.GetRolesAsync(this.activeData);
        this._srvDropDown.getAllPermissions();
    }
    this._loader.hide();
    if (this.successNotify) {
      this.onSuccessNotify();
      if (this.router.url == '/admin/switchsite') {
        this.refresh();
      }
    }
  }

  public setContextMenuItems(event: any) {
    this.contextMenuItems = [
      {
        label: 'Update',
        icon: 'pi pi-pencil',
        visible: this.router.url == '/admin/switchsite',
        command: () => this.openCloseForm()
      },
      {
        label: 'Edit',
        icon: 'pi pi-pencil',
        visible: this.isEditContextMenuVisible(event),
        command: () => this.openCloseForm()
      },
      {
        label: `Deactivate ${this.dataType.label}`,
        icon: 'pi pi-trash',
        visible: event.data.status == 'Active' && this.router.url != '/admin/contract-welder',
        command: () => this.openCloseDeleteDialog()
      },
      {
        label: `Activate ${this.dataType.label}`,
        icon: 'pi pi-plus',
        visible: event.data.status == 'Inactive' && this.router.url != '/admin/contract-welder',
        command: () => this.activateRow()
      },
      {
        label: 'Update Photo',
        icon: 'pi pi-image',
        visible: this.router.url == '/admin/contract-welder',
        command: () => this.openClosePhotoDialog()
      }
    ];
  }

  private isEditContextMenuVisible(event: any) {
    if (this.router.url == '/admin/welder' && event.data.status == 'Inactive') {
      return false;
    }

    return (
      this.router.url == '/admin/welder' || (this.router.url == '/admin/email') ||
      (event.data.status == 'Inactive' && this.router.url != '/admin/contract-welder')
    );
  }

  private activateRow() {
    if (this.router.url === '/admin/welder') {
      this.isContractWelder = this.selectedRow['isContractWelder'];
      this.openCloseActivateWelderDialog();
      return;
    }

    this.showConfirm();
  }

  public openCloseForm(type?: string) {
    if (this.displayForm) {
      this.displayForm = false;
    } else {
      if (type === 'New') {
        this.existing = false;
        this.formHeader = 'Add';
      } else {
        this.existing = true;
        this.formHeader = 'Update';
      }
      this.displayForm = true;
    }
  }

  public openCloseDeleteDialog() {
    if (this.displayDeleteDialog) {
      this.displayDeleteDialog = false;
    } else {
      this.reasonForDeletion = null;
      this.activateChevronStampNumber = null;
      this.activateContractorStampNumber = null;
      this.displayDeleteDialog = true;
    }
  }

  public deleteRow(selectedRow: any) {
    if (this.reasonForDeletion == '' || this.reasonForDeletion == null) {
      this.showNotification(
        new NotificationToast('error', 'Error: reason for deletion is required', '', 'error', 4000)
      );
    } else {
      this._loader.show();
      let dto = { ...selectedRow };
      dto.active = false;
      dto.reasonForDeletion = this.reasonForDeletion;
      switch (this.router.url) {
        case '/admin/user':
          this._srvUsers.AddorUpdateUserAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
          break;

        case '/admin/welder':
          this._srvWelders.AddorUpdateWelderAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
          break;

        case '/admin/filler-materials':
          this._srvFillMat.AddorUpdateFillerMaterialAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
          break;

        case '/admin/materials':
          this._srvMaterial.AddorUpdateMaterialAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
          break;

        case '/admin/pipe-sizes':
          this._srvSize.AddorUpdaterSizeAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
          break;

        case '/admin/wps':
          this._srvWPS.AddorUpdateWPSAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
          break;

        case '/admin/division':
          this._srvDivision.AddorUpdateDivisionAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
          break;

        case '/admin/unit':
          this._srvUnit.AddorUpdateUnitAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
          break;

        case '/admin/group':
          this._srvGroup.AddorUpdateWeldGroupAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
          break;

        case '/admin/rtpercentage':
          this._srvRTPercentage
            .AddorUpdateRTPercentageAsync(dto, true)
            .then(res => this.onSuccessOrFail(res.toString()));
          break;

        case '/admin/weldprocess':
          this._srvWeldProcess.AddorUpdateWeldProcessAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
          break;

        case '/admin/rolepermission':
          this._srvRole.AddorUpdateRoleAsync(dto, true, []).then(res => this.onSuccessOrFail(res.toString()));
          break;

        case '/admin/contract-company':
          this._srvContractCompany
            .AddorUpdateContractCompanyAsync(dto, true)
            .then(res => this.onSuccessOrFail(res.toString()));
          break;
      }
    }
  }

  public openCloseActivateWelderDialog() {
    if (this.displayActivateWelderDialog) {
      this.displayActivateWelderDialog = false;
    } else {
      this.activateChevronStampNumber = '';
      this.activateContractorStampNumber = '';
      this.displayActivateWelderDialog = true;
    }
  }

  public ActivateWelder(selectedRow: any) {
    if (!this.checkValidInput(selectedRow)) {
      this.showNotification(new NotificationToast('error', 'Required field missing', '', 'error', 4000));
    } else {
      this._loader.show();
      let dto = { ...selectedRow };
      dto.stampNumber = this.activateChevronStampNumber;
      dto['contractorStampNumber'] = this.activateContractorStampNumber;
      dto['contractCompanyId'] = this.activateContractCompanyId;
      this._srvWelders.AssignWelderStampAsync(dto).then(res => this.onSuccessOrFail(res.toString()));
    }
  }

  /* custom exceptions thrown from api are handled and return an error msg
    accepting string here allows us to consider that case and show notification with that custom error msg */
  public onSuccessOrFail(apiResponse: string) {
    if (apiResponse && apiResponse.toLocaleLowerCase() == 'true') {
      this.selectedRow = null;
      this.displayForm = false;
      this.displayDeleteDialog = false;
      this.displayActivateWelderDialog = false;
      this.dataType = this.setupDataTypes();
      this.successNotify = true;
      this.getData();
    } else {
      this._loader.hide();
      if (apiResponse && apiResponse.toLocaleLowerCase() == 'false') {
        this.showNotification(
          new NotificationToast('error', `Error updating ${this.dataType.label}`, '', 'error', 6000)
        );
      } else {
        //case for custom exceptions thrown from api
        this.showNotification(new NotificationToast('error', apiResponse, '', 'error', 6000));
      }
    }
  }

  private showConfirm() {
    this._notify.clear();
    let summary = '';
    if (this.router.url === '/admin/welder') {
      summary = `${this.selectedRow['firstName']} ${this.selectedRow['lastName']} will be updated to an active ${this.dataType.label}`;
    } else {
      summary = `${this.selectedRow[this.dataType.field]} will be updated to active`;
    }
    this._notify.add({
      key: 'confirm',
      sticky: true,
      severity: 'warn',
      summary: summary,
      detail: 'Confirm to proceed'
    });
  }

  public onConfirm(selectedRow: any) {
    this._notify.clear();
    this._loader.show();
    let dto = { ...selectedRow };
    dto.active = true;
    switch (this.router.url) {
      case '/admin/user':
        this._srvUsers.AddorUpdateUserAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
        break;

      case '/admin/filler-materials':
        dto.reasonForDeletion = null;
        this._srvFillMat.AddorUpdateFillerMaterialAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
        break;

      case '/admin/materials':
        dto.reasonForDeletion = null;
        this._srvMaterial.AddorUpdateMaterialAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
        break;

      case '/admin/pipe-sizes':
        dto.reasonForDeletion = null;
        this._srvSize.AddorUpdaterSizeAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
        break;

      case '/admin/wps':
        dto.reasonForDeletion = null;
        this._srvWPS.AddorUpdateWPSAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
        break;

      case '/admin/division':
        dto.reasonForDeletion = null;
        this._srvDivision.AddorUpdateDivisionAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
        break;

      case '/admin/unit':
        dto.reasonForDeletion = null;
        this._srvUnit.AddorUpdateUnitAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
        break;

      case '/admin/group':
        dto.reasonForDeletion = null;
        this._srvGroup.AddorUpdateWeldGroupAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
        break;

      case '/admin/rtpercentage':
        dto.reasonForDeletion = null;
        this._srvRTPercentage.AddorUpdateRTPercentageAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
        break;

      case '/admin/weldprocess':
        dto.reasonForDeletion = null;
        this._srvWeldProcess.AddorUpdateWeldProcessAsync(dto, true).then(res => this.onSuccessOrFail(res.toString()));
        break;

      case '/admin/rolepermission':
        dto.reasonForDeletion = null;
        this._srvRole
          .AddorUpdateRoleAsync(dto, true, dto.rolePermission)
          .then(res => this.onSuccessOrFail(res.toString()));
        break;

      case '/admin/contract-company':
        dto.reasonForDeletion = null;
        this._srvContractCompany
          .AddorUpdateContractCompanyAsync(dto, true)
          .then(res => this.onSuccessOrFail(res.toString()));
        break;
    }
  }

  public onReject() {
    this._notify.clear();
  }

  private showNotification(notificationToast: NotificationToast) {
    this._notifSrv.sendNotification(notificationToast);
  }

  private onSuccessNotify() {
    this.showNotification(new NotificationToast('success', `${this.dataType.label} successfully updated`, '', 'error'));
    this.successNotify = false;
  }
  //#endregion 'Shared Methods'

  //#region 'Validation'

  // custom validation for 'Activate Contract Welder form'
  public checkValidInput(selectedRow: any): boolean {
    let flag = true;
    this.clearValidFlags(Object.keys(this.isValidFlags));
    if (selectedRow.isContractWelder) {
      if (this.activateContractorStampNumber == '' || this.activateContractorStampNumber == null) {
        this.isValidFlags.contractorStampNumber = false;
        flag = false;
      }
      if (this.activateContractCompanyId == null) {
        this.isValidFlags.contractCompanyId = false;
        flag = false;
      }
    }
    if (this.activateChevronStampNumber == '' || this.activateChevronStampNumber == null) {
      this.isValidFlags.chevronStampNumber = false;
      flag = false;
    }
    return flag;
  }

  public clearValidFlags(keys: string[]) {
    keys.forEach(key => (this.isValidFlags[key] = true));
  }
  //#endregion 'Validation'

  //#region 'Contract Welder Photo'
  public openClosePhotoDialog() {
    this.displayPhotoDialog = !this.displayPhotoDialog;
  }

  public savePhoto(fileString: any) {
    this.selectedRow['photo'] = fileString;
    this._srvContractorIdentification
      .UpdateContractorPhotoByIdAsync(this.selectedRow)
      .then(res => this.onSuccessOrFail(res.toString()));
    this.displayPhotoDialog = false;
  }

  filterContractWelderTable() {
    if (this.companyFilter === null) {
      // If no item is selected or dropdown is cleared, show all data
      this.tableData = [...this.allPhotoDataStored];
    } else {
      // Filter the table based on the selected company name
      this.tableData = this.allPhotoDataStored.filter(welder => welder.contractCompanyName === this.companyFilter);
    }
  }

  filterWelderTable() {
    if (this.welderTypeFilter === null) {
      // If no item is selected or dropdown is cleared, show all data
      this.tableData = [...this.allWelderDataStored];
    } else {
      // Filter the table based on the selected welder type
      this.tableData = this.allWelderDataStored.filter(welder => welder.welderType === this.welderTypeFilter);
    }
  }
  //#endregion 'Contract Welder Photo'

  //region Clear reload page
  public refresh() {
    this.router.navigateByUrl('/');
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    this._loader.show();
  }
  //#endregion reload page
}
