<!-- #region Title -->
<div class="row">
  <div class="col-12 d-flex justify-content-center" *ngIf="this.router.url == '/reporting/weld-stats'">
    <h2>Weld Stats</h2>
  </div>
  <div class="col-12 d-flex justify-content-center" *ngIf="this.router.url == '/reporting/welder-stats'">
    <h2>Welder RT Reporting</h2>
  </div>
  <div class="col-12 d-flex justify-content-center" *ngIf="this.router.url == '/reporting/welder-stamp-id-history'">
    <h2>Welder Stamp ID History Reporting</h2>
  </div>
</div>
<!-- #endregion -->

<!-- #region Filters Row 1 -->
<div class="row">
  <!-- #region Division -->
  <div class="col-2" *ngIf="this.router.url == '/reporting/weld-stats'">
    <h6>Divisions</h6>
    <p-dropdown
      [options]="divisions.items"
      (onChange)="getUnits(); checkForInactiveFilters()"
      optionLabel="name"
      optionValue="id"
      [(ngModel)]="weldLogParams.divisionid"
      placeholder="Select"
      [showClear]="true"
      [filter]="true"
      [ngClass]="{ 'pending-filter-drp': weldLogParams.divisionid != activeWeldFilterParams.divisionid }"
    ></p-dropdown>
  </div>
  <!-- #endregion -->

  <!-- #region Units -->
  <div class="col-2" *ngIf="this.router.url == '/reporting/weld-stats'">
    <h6>Units</h6>
    <p-dropdown
      [options]="units.items"
      (onChange)="checkForInactiveFilters()"
      optionLabel="name"
      optionValue="id"
      [(ngModel)]="weldLogParams.unitid"
      placeholder="Select a Unit"
      [showClear]="true"
      [filter]="true"
      [ngClass]="{ 'pending-filter-drp': weldLogParams.unitid != activeWeldFilterParams.unitid }"
    ></p-dropdown>
  </div>
  <!-- #endregion -->

  <!-- #region Groups -->
  <div class="col-2" *ngIf="this.router.url == '/reporting/weld-stats'">
    <h6>Groups</h6>
    <p-dropdown
      [options]="groups.items"
      (onChange)="checkForInactiveFilters()"
      optionLabel="name"
      optionValue="id"
      [(ngModel)]="weldLogParams.groupid"
      placeholder="Select"
      [showClear]="true"
      [filter]="true"
      [ngClass]="{ 'pending-filter-drp': weldLogParams.groupid != activeWeldFilterParams.groupid }"
    ></p-dropdown>
  </div>
  <!-- #endregion -->

  <!-- #region Welder -->
  <div class="col-2" *ngIf="this.router.url == '/reporting/welder-stats'">
    <h6>Welder</h6>
    <p-dropdown
      optionValue="id"
      optionLabel="name"
      placeholder="Select a Welder"
      [options]="welders.items"
      appendTo="body"
      (onChange)="checkForInactiveFilters()"
      [(ngModel)]="welderStatsParams.fullName"
      [showClear]="true"
      [filter]="true"
      [ngClass]="{ 'pending-filter-drp': welderStatsParams.fullName != activeWelderFilterParams.fullName }"
    ></p-dropdown>
  </div>

  <!-- #region Welder -->
  <div class="col-2" *ngIf="this.router.url == '/reporting/welder-stamp-id-history'">
    <h6>Welder</h6>
    <p-dropdown
      optionValue="id"
      optionLabel="name"
      placeholder="Select a Welder"
      [options]="allwelders.items"
      appendTo="body"
      (onChange)="checkForInactiveFilters()"
      [(ngModel)]="welderStampIdHistoryParams.welderName"
      [showClear]="true"
      [filter]="true"
      [ngClass]="{ 'pending-filter-drp': welderStampIdHistoryParams.welderName != activeWelderStampIdFilterParams.welderName }"
    ></p-dropdown>
  </div>

  <!-- #region Stamp Number -->
  <div class="col-1" *ngIf="this.router.url == '/reporting/welder-stamp-id-history'">
    <h6>Stamp Number</h6>
    <span [ngClass]="{ 'pending-filter-input': welderStampIdHistoryParams.stampNumber != activeWelderStampIdFilterParams.stampNumber }">
      <input
        type="text"
        pInputText
        class="w100"
        (input)="checkForInactiveFilters()"
        [(ngModel)]="welderStampIdHistoryParams.stampNumber"
        (keypress)="keyValidation.alphanumericCommaSeparated($event)"
      />
    </span>
  </div>
  <!-- #endregion Stamp Number -->

  <!-- #endregion Division -->

  <!-- #region Date Welded From -->
  <div class="col-1">
    <h6>Date from</h6>
    <p-calendar
      inputId="basic"
      (onSelect)="onDateFromSelect($event)"
      [(ngModel)]="filterFromDate"
      dataType="string"
      dateFormat="yy-mm-dd"
      [readonlyInput]="true"
      [ngClass]="{ 'pending-filter-cal': welderStampIdHistoryParams.allocationIdFromDate != activeWelderStampIdFilterParams.allocationIdFromDate }"
    ></p-calendar>
  </div>
  <!-- #endregion -->

  <!-- #region Date Welded To -->
  <div class="col-1">
    <h6>To</h6>
    <p-calendar
      inputId="basic"
      (onSelect)="onDateToSelect($event)"
      [(ngModel)]="filterToDate"
      dataType="string"
      dateFormat="yy-mm-dd"
      [readonlyInput]="true"
      [ngClass]="{ 'pending-filter-cal': welderStampIdHistoryParams.allocationIdToDate != activeWelderStampIdFilterParams.allocationIdToDate }"
    ></p-calendar>
  </div>
  <!-- #endregion -->

  <!-- #region Filter Button -->
  <div class="col-5 divCenter">
    <p-button styleClass="p-button-m mt-4 mr-3" (click)="submitFilter()" [ngClass]="{ 'inactive-filter-btn': inactiveFilters }"><em class="pi pi-filter mr-2"></em> Filter Report</p-button>
    <!-- #endregion Filter Button -->

    <!-- #region Export to Excel  Button -->
    <p-button styleClass="p-button-m mt-4" (click)="exportToExcel()"><em class="pi pi-file-excel mr-2"></em> Export to Excel</p-button>
    <!-- #endregion Export to Excel  Button -->
  </div>
</div>
<br />
<!-- #endregion -->

<!-- #region Engineering Information Header -->
<div class="row">
  <div class="col-12 center" *ngIf="this.router.url == '/reporting/weld-stats'">
    <h4>Engineering Information</h4>
  </div>
  <div class="col-12 center" *ngIf="this.router.url == '/reporting/welder-stats'">
    <h4>Welder Information</h4>
  </div>
</div>
<br />
<!-- #endregion Engineering Information Header -->

<!-- #region 'Weld Stats Tables' -->
<div class="row" *ngIf="this.router.url == '/reporting/weld-stats'">
  <!-- #region 'Overall Weld Stats Table' -->
  <div class="col-2">
    <h5>Overall Weld Statistics</h5>
    <p-table [value]="[{}]">
      <ng-template pTemplate="body">
        <tr>
          <td style="font-size: 16px; font-weight: 1000">Total Welds</td>
          <td style="font-size: 16px; font-weight: 1000">{{ weldStats.weldStatsTotal.totalWelds }}</td>
        </tr>
        <tr>
          <td>Accepted</td>
          <td>{{ weldStats.weldStatsTotal.totalAccepted }}</td>
        </tr>
        <tr>
          <td>Inspected</td>
          <td>{{ weldStats.weldStatsTotal.totalInspected }}</td>
        </tr>
        <tr>
          <td>Rejected</td>
          <td>{{ weldStats.weldStatsTotal.totalRejected }}</td>
        </tr>
        <tr>
          <td>Reject Rate</td>
          <td>{{ weldStats.weldStatsTotal.totalRejectRate }}%</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <!-- #endregion 'Overall Weld Stats Table' -->

  <div class="col-1"></div>
  <!-- spacing between tables -->

  <!-- #region 'Joint Type Weld Stats Table' -->
  <div class="col-4">
    <h5>Welds by Joint Type</h5>
    <p-table [value]="weldStats.weldStatsByJointType">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" class="header">Joint Type</th>
          <th scope="col" class="header">Total Welds</th>
          <th scope="col" class="header">Accepted</th>
          <th scope="col" class="header">Rejected</th>
          <th scope="col" class="header">Repaired</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-stats>
        <tr>
          <td>{{ stats.jointTypeName }}</td>
          <td>{{ stats.statsTotalWelds }}</td>
          <td>{{ stats.statsAccepted }}</td>
          <td>{{ stats.statsRejected }}</td>
          <td>{{ stats.statsRepaired }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <!-- #endregion 'Joint Type Weld Stats Table' -->

  <div class="col-1"></div>
  <!-- spacing between tables -->

  <!-- #region 'QA Type Weld Stats Table' -->
  <div class="col-4">
    <h5>Welds by Volumetric Inspection Type</h5>
    <p-table [value]="weldStats.weldStatsByQAType">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" class="header">QA Type</th>
          <th scope="col" class="header">Total Welds</th>
          <th scope="col" class="header">Accepted</th>
          <th scope="col" class="header">Rejected</th>
          <th scope="col" class="header">Repaired</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-stats>
        <tr>
          <td>{{ stats.qaTypeName }}</td>
          <td>{{ stats.statsTotalWelds }}</td>
          <td>{{ stats.statsAccepted }}</td>
          <td>{{ stats.statsRejected }}</td>
          <td>{{ stats.statsRepaired }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <!-- #endregion 'QA Type Weld Stats Table' -->
</div>
<!-- #endregion 'Weld Stats Tables' -->

<!-- #region 'Welder Stats Tables' -->
<div class="row" *ngIf="this.router.url == '/reporting/welder-stats'">
  <!-- #region 'Welder RT Report Table' -->
  <br />
  <p-table
    [value]="welderStats"
    responsiveLayout="scroll"
    [scrollable]="true"
    [paginator]="true"
    paginatorPosition="both"
    paginatorDropdownAppendTo="body"
    [rows]="25"
    scrollDirection="both"
    [rowsPerPageOptions]="[25, 50, 75, 100]"
  >
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" class="header" pSortableColumn="fullName">Welder <p-sortIcon field="fullName"></p-sortIcon></th>
        <th scope="col" class="header" pSortableColumn="description">Description <p-sortIcon field="description"></p-sortIcon></th>
        <th scope="col" class="header" pSortableColumn="totalWelds">Total Welds <p-sortIcon field="totalWelds"></p-sortIcon></th>
        <th scope="col" class="header" pSortableColumn="totalBWs">Total BWs <p-sortIcon field="totalBWs"></p-sortIcon></th>
        <th scope="col" class="header" pSortableColumn="bWsInspected">BWs Inspected <p-sortIcon field="bWsInspected"></p-sortIcon></th>
        <th scope="col" class="header" pSortableColumn="bWsRejected">BWs Rejected <p-sortIcon field="bWsRejected"></p-sortIcon></th>
        <th scope="col" class="header" pSortableColumn="rejectRate">Reject Rate <p-sortIcon field="rejectRate"></p-sortIcon></th>
        <th scope="col" class="header" pSortableColumn="bwrtCoverage">BW RT Coverage <p-sortIcon field="bwrtCoverage"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-welderStats>
      <tr [ngClass]="{ 'sub-header': welderStats.description.includes('Facility Total') || welderStats.description.includes('Welder Total') }">
        <td>{{ welderStats.fullName }}</td>
        <td>{{ welderStats.description }}</td>
        <td>{{ welderStats.totalWelds }}</td>
        <td>{{ welderStats.totalBWs }}</td>
        <td>{{ welderStats.bWsInspected }}</td>
        <td>{{ welderStats.bWsRejected }}</td>
        <td>{{ welderStats.rejectRate }}</td>
        <td>{{ welderStats.bwrtCoverage }}</td>
      </tr>
    </ng-template>
  </p-table>
  <br /><br />
  <!-- #endregion 'Welder RT Report Table' -->
</div>
<!-- #endregion 'Welder Stats Tables' -->

<!-- #region 'Welder Stats Tables' -->
<div class="row" *ngIf="this.router.url == '/reporting/welder-stamp-id-history'">
  <!-- #region 'Welder RT Report Table' -->
  <br />
  <p-table
    [value]="welderStampIdHistory"
    responsiveLayout="scroll"
    [scrollable]="true"
    [paginator]="true"
    paginatorPosition="both"
    paginatorDropdownAppendTo="body"
    [rows]="25"
    scrollDirection="both"
    [rowsPerPageOptions]="[25, 50, 75, 100]"
  >
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" class="header" pSortableColumn="welderName">Welder <p-sortIcon field="welderName"></p-sortIcon></th>
        <th scope="col" class="header" pSortableColumn="stampNumber">Stamp Number <p-sortIcon field="stampNumber"></p-sortIcon></th>
        <th scope="col" class="header" pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
        <th scope="col" class="header" pSortableColumn="welderType">Welder Type <p-sortIcon field="welderType"></p-sortIcon></th>
        <th scope="col" class="header" pSortableColumn="allocationStartDate">Allocation Start Date <p-sortIcon field="allocationStartDate"></p-sortIcon></th>
        <th scope="col" class="header" pSortableColumn="allocationEndDate">Allocation End Date <p-sortIcon field="allocationEndDate"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-welderStampIdHistory>
      <tr>
        <td>{{ welderStampIdHistory.welderName }}</td>
        <td>{{ welderStampIdHistory.stampNumber }}</td>
        <td>{{ welderStampIdHistory.status }}</td>
        <td>{{ welderStampIdHistory.welderType }}</td>
        <td>{{ welderStampIdHistory.allocationStartDate | date: 'yyyy-MM-dd HH:mm' }}</td>
        <td>{{ welderStampIdHistory.allocationEndDate | date: 'yyyy-MM-dd HH:mm' }}</td>
      </tr>
    </ng-template>
  </p-table>
  <br /><br />
  <!-- #endregion 'Welder RT Report Table' -->
</div>
<!-- #endregion 'Welder Stats Tables' -->
